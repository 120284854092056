import sha1 from "js-sha1";

// 随机生成手机号
export function getMoblieNum() {
  var numArray = new Array(
    "139",
    "138",
    "137",
    "136",
    "135",
    "134",
    "159",
    "158",
    "157",
    "150",
    "151",
    "152",
    "188",
    "187",
    "182",
    "183",
    "184",
    "178",
    "130",
    "131",
    "132",
    "156",
    "155",
    "186",
    "185",
    "176",
    "133",
    "153",
    "189",
    "180",
    "181",
    "177"
  );
  var arraryLength = numArray.length;
  var i = parseInt(Math.random() * arraryLength);
  var num = numArray[i];
  for (var j = 0; j < 8; j++) {
    num = num + Math.floor(Math.random() * 10);
  }
  return num;
}

export function getServiceUrl(data = {}) {

  const { userName, conferenceNo } = data

  let nonce = Math.round(1, 100);
  let timestamp = new Date().getTime();
  let web_token = getMoblieNum();

  let c_email = web_token + "@100doc.com.cn";
  let encoded_desc = "性别:无;城市:无;医院:无;科室:无;职称:无";
  let c_org = "北京医百科技";
  let c_name = userName || '';
  let udesk_wd = "医百会议-Web-"; // TODO: 
  let c_phone = web_token;
  let str =
    "nonce=" +
    nonce +
    "&timestamp=" +
    timestamp +
    "&web_token=" +
    web_token +
    "&caf4505ab2cd356381a29387a76ecc3d";
  let signature = sha1(str).toString().toUpperCase();

  let openUrl =
    "https://1597866.s4.udesk.cn/im_client/?web_plugin_id=543" +
    "&c_email=" +
    c_email +
    "&c_desc=" +
    encoded_desc +
    "&c_org=" +
    c_org +
    "&c_name=" +
    c_name +
    "&c_phone=" +
    c_phone +
    "&nonce=" +
    nonce +
    "&signature=" +
    signature +
    "&timestamp=" +
    timestamp +
    "&web_token=" +
    web_token +
    "&udesk_wd=" +
    udesk_wd;

  if (conferenceNo) {
    openUrl = openUrl + '&c_tags=' + conferenceNo
  }
  
  return openUrl
}
export const helpUrl ="https://cloud.100doc.com.cn/product/docCenter?type=10"
// 区分设备类型
export function getOS() { 
  const agent = navigator.userAgent.toLowerCase();
  const MAC =
    /macintosh|mac os x/i.test(navigator.userAgent) && !window.MSStream;
  if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
    return "windows"; 
  }
  if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
    return "windows";  
  }
  if (MAC) {
    return "mac";
  }
  return "unknow";
}

export function checkApp(url, callback) {
  const link = document.createElement("iframe");
  try {
    link.style.display = "none";
    link.src = url
    document.body.appendChild(link);
    let loadDateTime = new Date();
    setTimeout(() => {
      callback()
      document.body.removeChild(link);
      let timeOutDateTime = new Date();
      if (timeOutDateTime - loadDateTime < 8000) {
        let href = 'https://cloud.100doc.com.cn/product/ybMeeting/dashboard'
        window.open(href,'_blank') 
      }
    }, 4000);
  } catch (error) {
    console.log("download-err", error);
    document.body.removeChild(link);
    callback()
  }
}

export function getBrowser() {
  const ua = window.navigator.userAgent

  const ret = {},
      webkit = ua.match(/WebKit\/([\d.]+)/),
      chrome = ua.match(/Chrome\/([\d.]+)/),
      edge = ua.match(/Edg\/([\d.]+)/) ||
          ua.match(/Edge\/([\d.]+)/),

      ie = ua.match(/MSIE\s([\d\.]+)/) ||
          ua.match(/(?:trident)(?:.*rv:([\w.]+))?/i),
      firefox = ua.match(/Firefox\/([\d.]+)/),
      safari = ua.match(/Version\/([\d.]+).*Safari/),
      opera = ua.match(/OPR\/([\d.]+)/);
  webkit && (ret.webkit = parseFloat(webkit[1]))
  chrome && (ret.chrome = parseFloat(chrome[1]))
  edge && (ret.edge = parseFloat(edge[1]))
  ie && (ret.ie = parseFloat(ie[1]))
  firefox && (ret.firefox = parseFloat(firefox[1]))
  safari && (ret.safari = parseFloat(safari[1]))
  opera && (ret.opera = parseFloat(opera[1]))
  return ret
}

/**
 * 秒值转成时分秒
 * @param {*} value 
 * @returns 
 */
 export function formatSeconds (
    value, 
    hourCondition, 
    minuteCondition, 
    secondCondition) {
  var secondTime = parseInt(value);// 秒
  var minuteTime = 0;// 分
  var hourTime = 0;// 小时
  if(secondTime > 59) {//如果秒数大于60，将秒数转换成整数
      //获取分钟，除以60取整数，得到整数分钟
      minuteTime = parseInt(secondTime / 60);
      //获取秒数，秒数取佘，得到整数秒数
      secondTime = parseInt(secondTime % 60);
      //如果分钟大于60，将分钟转换成小时
      if(minuteTime > 59) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
      }
  }

  var result = ''

  if(secondTime > 0 && secondCondition.show) {
     result = (secondCondition.isPadZero ?  String(secondTime).padStart(2, '0') 
     : String(secondTime)) + secondCondition.unit
  }
  if(minuteTime > 0 && minuteCondition.show) { 
      result = (minuteCondition.isPadZero ?  String(minuteTime).padStart(2, '0') 
      : String(minuteTime)) + minuteCondition.unit + result
  }
  if(hourTime > 0 && hourCondition.show) {
      result = (hourCondition.isPadZero ?  String(hourTime).padStart(2, '0') 
      : String(hourTime)) + hourCondition.unit + result
  }
  return result
}


//保存数据到本地
export function saveLocal(key, value) {
  if (typeof value === "string") {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
// 删除本地存储
export function removeLocal(key) {
  localStorage.removeItem(key);
};

// 读取本地的数据
export function loadLocal(key) {
  let content = localStorage.getItem(key);
  if (content !== null ) {

    content = content;
  }
  return content;
};

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
 export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
 export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * 会议号格式化 如："XXX XXX XXX"
 * @param {*} confNo 
 * @param {*} fmtChar 
 * @param {*} maxLen 
 * @returns 
 */
export function formatCode(confNo, fmtChar, maxLen) {
  fmtChar = fmtChar || " ";
  maxLen = maxLen || 11;
  if (confNo === '' || confNo === undefined) {
      return ''
  }
  confNo = confNo.toString().trim().replace(/[^\d]/g, "");

  if (confNo.length > maxLen) {
      confNo = confNo.substr(0, maxLen);
  }
  var result = confNo.substr(0, 3);
  if (confNo.length === 11) {
      result += fmtChar + confNo.substr(3, 4) + fmtChar + confNo.substr(7);
  } else {
      if (confNo.length >= 3) {
          result += fmtChar + confNo.substr(3, 3);
      }
      if (confNo.length >= 6) {
          result += fmtChar + confNo.substr(6);
      }
  }
  return result;
}


export function getUrlDate(url){
  let args = {} //存储到对象中
        let num = url.indexOf('?') //获取？的位置
        url = url.substr(num + 1) //截取参数信息string
        let arr = url.split('&') //以&分割字付出啊安并保存到数组中
        for (var i = 0; i < arr.length; i++) {
          var pair = arr[i].split('=')
          args[pair[0]] = pair[1];
          args[pair[0]] = decodeURI(args[pair[0]]);
        }
        return args;
}