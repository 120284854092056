/**
     * 图片预加载
     */

 export function preloadImage(url) {
    return new Promise(function(resolve, reject) {
        let img = new Image();
        img.src = url
        img.onload = function() { resolve(img) }
        img.onerror = function() { reject(url) }
    })
}

//预加载图片配置
const imgs = [
    require('../assets/images/face/recover/nocheck_use.png'),
    require('../assets/images/face/recover/nocheck_disable.png'),

    require('../assets/images/face/white/check_nouse.png'),
    require('../assets/images/face/white/check_use.png'),
    require('../assets/images/face/white/nocheck_nouse.png'),
    require('../assets/images/face/white/nocheck_use.png'),

    require('../assets/images/face/skin/check_nouse.png'),
    require('../assets/images/face/skin/check_use.png'),
    require('../assets/images/face/skin/nocheck_nouse.png'),
    require('../assets/images/face/skin/nocheck_use.png'),
    
    require('../assets/images/face/thin/check_nouse.png'),
    require('../assets/images/face/thin/check_use.png'),
    require('../assets/images/face/thin/nocheck_nouse.png'),
    require('../assets/images/face/thin/nocheck_use.png'),

    require('../assets/images/face/pare/check_nouse.png'),
    require('../assets/images/face/pare/check_use.png'),
    require('../assets/images/face/pare/nocheck_nouse.png'),
    require('../assets/images/face/pare/nocheck_use.png'),

    require('../assets/images/face/eye/check_nouse.png'),
    require('../assets/images/face/eye/check_use.png'),
    require('../assets/images/face/eye/nocheck_nouse.png'),
    require('../assets/images/face/eye/nocheck_use.png'),

    require('../assets/images/face/chin/check_nouse.png'),
    require('../assets/images/face/chin/check_use.png'),
    require('../assets/images/face/chin/nocheck_nouse.png'),
    require('../assets/images/face/chin/nocheck_use.png'),
]

export function preloadMutImage() {
    let promiseArr = []
    imgs.forEach(url => {
        promiseArr.push(preloadImage(url))
    })
    return Promise.all(promiseArr)
}
