<template>
  <div ref="errorHandler">
    <slot></slot>
  </div>
</template>

<script>
import { removeUserInfo } from '@/utils/auth'
import { loganLog } from "@/utils/log"
export default {
  data() {
     return {
     }
  },

  props: {
    
  },

  components: {
  },

  computed: {
    
  },
  mounted() {
     // 注册失败处理
     this.$eventBus.$on('register-error', error=> {
       //503:用户已存在
       //505 验证码错误
      if (error.code === 503) {
        this.$router.push({ name: 'errorRegister' })
      }
      if (error.code === 505) {
        this.$toast(error.msg)
      }
    })
    // 登录失败处理
    this.$eventBus.$on('login-error', error => {
      if(error.message.includes('Network Error')) return
      //510密码输入错误
      //512验证码错误
      this.$dialog.alert({
        title: this.$t('login.tips'),
        message: error.message,
        confirmButtonText: this.$t('login.sure'),
        theme: 'round-button',
        showCancelButto:false,
      })
      .then(() => {
        console.log('errorhandler this');
      })
      });
    //请求拦截 异常处理
    this.$eventBus.$on('request-error', error => {

      //处理网络错误
      if (error.message && (error.message === 'Network Error' 
        || error.message.indexOf('timeout of') > -1)) {
        this.$eventBus.$emit('network-error', error)
        return
      }

      const goBackCodeList = [60034, 60007, 60008, 60027, 60029]
      const toastCodeList = [60018] //<60018>成为主持人密码错误

      //刷新token失败 重新登陆
      if (error.error_type === 'refresh_token_error') {
        removeUserInfo()
        this.notify(this.$t('login.tokenTime'))
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 1100);
      } else if (goBackCodeList.indexOf(error.code) > -1) { // 回到首页
        this.notify(error.msg)
        setTimeout(() => {
          this.$router.push({ name: 'home' })
        }, 1100);
      } else if(toastCodeList.indexOf(error.code) > -1) { //需要弹toast
        this.$toast(error.msg)
      } else if(error.code === 500) { //服务器异常文案
        this.notify(error.msg || this.$t('login.netFail'))
      } else if(!error.code || error.code !== 401) { //其它
         const msg = error.message || error.msg || this.$t('login.netFail')
         this.notify(error.code ? `[${error.code}]${msg}` : msg)
      }
    })

    //网络错误 异常处理
    this.$eventBus.$on('network-error', error => {
      console.error('network-error', error);

      //网络连接中断
      if (error.message && error.message === 'Network Error') {
        //this.notify(this.$t('meeting.netWorkBad'))
        this.$eventBus.$emit('show-network-error',  {
          show: true
        })
        return
      }

      //网络请求超时
      if (error.message && error.message.indexOf('timeout of') > -1) {
        this.notify(this.$t('meeting.netWorkTimeout'))
        return
      }
    })

    //加入会议失败 处理
    this.$eventBus.$on('join-error', (error, cb, params) => {
      console.error('join-error', error);
      //异常码
      const errorCodeList = [401, 501, 502, 503, 504, 505, 60034, 60027, 60029, 60018]
    
      //请输入会议密码
      if (error.code === 60006) {
        this.$eventBus.$emit('join-password-handle')
      } 

      //输入密码错误
      else if (error.code === 60012) { 
        this.$eventBus.$emit('join-password-error-handle')  
      } 

      //主持人正在开另外一场会议
      else if (error.code === 60011) {
        this.$eventBus.$emit('other-host-handle', error, cb, params)
      } 
      
      //请等待主持人开始当前会议
      else if (error.code === 60005) { 
        this.$eventBus.$emit('wait-host-handle', error, cb, params)
      } 
      
      //是否结束当前正在进行中的会议
      else if (error.code === 60010) { 
         this.$eventBus.$emit('end-meeting-handle', error, cb, params)
      }

      //会议号不存在 等其它错误
      else if (error.code && error.msg && errorCodeList.indexOf(error.code) === -1) { 
         this.$toast(error.msg)
      } else {
         loganLog(error, '入会异常')
         this.$eventBus.$emit('request-error', error)
      }
    })

    //TODO: 整理code


    // 会场 进入会议后 发给errorHandler
    this.$eventBus.$on('init-rtc-event', this.initRTCevent);

  },
  
  methods: {
    notify(msg) {
      this.$notify({
          message: msg,
          color: '#FF4D4F',
          background: '#ffeded'
      })
    },

    // 绑定会场 监听事件
    initRTCevent() {

      this.sdk.on('error', error => {
        console.error('sdk抛出错误-----')
        console.log(error)

      })
      // 入会失败

      // 恢复音频流失败
      this.$eventBus.$on('unmute-audio-error', error => {
        this.notify('打开麦克风失败');
        this.$store.commit('member/updateUser', {
          userId: this.$configs.peerId,
          isUseHuaTong: false
        });
      });


      this.$eventBus.$on('end-meeting', data => this.notify(data.msg))
      this.$eventBus.$on('network-disconnect', () => {
         this.$toast(this.$t('meeting.netWorkBad'))
      })

    },
  },
};
</script>

<style lang="less" scoped>
</style>
