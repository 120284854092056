import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

const store = new Vuex.Store({
    modules,
    getters,
    state: {
        scrollColor: 'hsla(220,4%,58%,.3)', // 滚动条组件颜色 vue-happy-scroll
        scrollTop: 0, // 使用自定义滚动条后移动滚动条的top值
        // 开始flexible组件后，内容使用了相对定位, 要减去对应的值，其他组件才能定位正常
        // 目前影响到的组件有windows、rangeArea
        moduleContentPosition: {
            left: 0,
            top: 0
        },


        isShowSettingPanel: false, //是否展示设置面板 
        settingPanelDefaultIndex: 0, //设置面板默认项索引,
        isShowVideoPanel: false, //是否展示视频预览面板
        isShowDeviceSmallWin: false //是否有展示底部设备列表窗体 
    },
    mutations: {
        // 设置自定义滚动条高
        setScrollTop(state, value) { // TODO: 去掉
            state.scrollTop = value
        },



        // 是否显示设置面板
        isShowSettingPanel(state, isShow) {
            state.isShowSettingPanel = isShow
        },
        //设置面板默认展示项索引
        settingPanelDefaultIndex(state, value) {
            state.settingPanelDefaultIndex = value
        },
        //是否显示视频预览面板
        isShowVideoPanel(state, value) {
            state.isShowVideoPanel = value
        },
        //是否有展示底部设备列表窗体
        isShowDeviceSmallWin(state, value) {
            state.isShowDeviceSmallWin = value
        },
    }
})

export default store