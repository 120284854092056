/**
 * 小程序美颜支持设备 白名单
 * 
 * 苹果 11/12/13
 * 红米K20 Pro，小米 10 
 * 'Redmi K20 Pro'
 * Mi 10
 */
export const SupportDevices = [
   'iPhone 11',
   'iPhone 12',
   'iPhone 13'
]
