<template>
  <img v-if="imgUrl" :src="setImgUrl" :style="styleObject"  @click="$emit('click')" />
  <svg v-else
    :style="styleObject"
    aria-hidden="true"
    @click="$emit('click')"
  >
    <use :xlink:href="setIcon" />
  </svg>
</template>

<script>
import "@/assets/css/iconFont/iconfont.js";
export default {
  props: {
    target: { default: "" }, // 图标样式名称
    fontSize: { default: 24 }, //图标大小
    iconName: { default: "" }, // icon名称
    imgUrl: { default: "" }, //自定义图片图标地址 相对于assets/images目录下

    customStyle: {
      default: function () {
        return {}
      },
      type: Object
    }
  },
  computed: {
    // 设置图标名称
    setIcon() {
      return "#" + this.iconName;
    },

    // 设置图片地址
    setImgUrl() {
      return require(`@/assets/images/${this.imgUrl}`)
    },

    px2vw() {
      return `${(this.fontSize / 750) * 100}vw`
    },

    styleObject() {
      return {
        ...this.customStyle,
        width: this.px2vw, 
        height: this.px2vw
      }
    }
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
</style>
