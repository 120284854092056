import { setToken, removeToken } from '@/utils/auth'
const state = {
    token: '',
    userId: '',
    avatar: '',
    name: '',
    conferenceNo: '',
    realName: '',
    userInfo: {},

}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USERID: (state, userId) => {
        state.userId = userId
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_CONFERENCENO: (state, conferenceNo) => {
        state.conferenceNo = conferenceNo
    },
    SET_REALNAME: (state, realName) => {
        state.realName = realName
    },
    setUserInfo: (state, userInfo) => {
        state.userInfo = userInfo
    }
}

const actions = {
    getInfo({ commit, state }, res) {
        commit('SET_TOKEN', res.phone)
        commit('SET_NAME', res.username)
        commit('SET_AVATAR', res.avatar)
        commit('SET_USERID', res.userId)
        commit('SET_CONFERENCENO', res.conferenceNo)
        commit('SET_REALNAME', res.realName)
    },
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
