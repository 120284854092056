export const LANG_ZH = "zh-CN";
export const LANG_EN = "en-US";
export const LANG_AUTO = "auto";

export const FACE_CACHE_KEY = 'FACE_SETTING'; // 美颜配置缓存key

export const ERR_CAMERA_NO_GRANT = 80000303; //摄像头设备未授权，可能是权限被用户拒绝了

export const DEVICE_AUTH_SUCCESS = "1";
export const DEVICE_AUTH_FAIL = "2";
export const LOGIN_TYPE = {
  NONE: 0, // 非登录用户
  PERSONAL_PASSWORD: 1, // 个人密码登录
  PERSONAL_VERIFYCODE: 2, // 个人验证码登录
  ENTERPRISE: 3, // 企业邮箱登录
};
// 布局配置
export const LAYOUT_CONFIG = {
  GALLERY9: 1,
  GALLERY25: 2,
  COLUMN: 3,
  ROW: 4,
  FULL: 5,
};

export const ROLE_CODE = {
  HOST: 20, // 主持人
  CO_HOST: 10, // 联席主持人
  USER: 0, // 普通用户
};

// 排序权重
export const WEIGHT_CONFIG = {
  SELF: 9, // 自己
  HOST: 3,
  CO_HOST: 2,
  USER: 1,
};

export const MAX_CONF_TIME = 100 * 24 * 60 * 60 * 1000;

export const CONTROL_COMMAND = {
  HOST_JOIN_ROOM: "hostJoinRoom", // 主持人加入会议
  AUTOMATICGRANT: "automaticGrant", // 自动选举主持人
  CHANGE_NAME: "changeNameByHost", // 主持人更改参会人名字

  GRANT: "grant", // 授权角色变更

  TRANSFER_HOST: "transferHost", // 转移主持人
  RECOVER_HOST_PERMISSION: "recoverHostPermission", // 收回主持人权限
  APPLY_HOST: "applyHostPermission", // 申请成为主持人

  SINGLE_MUTE: "singleMute", // 单个静音
  SINGLE_UNMUTE: "singleUnMute", // 单个取消静音

  STOP_SHARE: "stopShare", // 停止共享
  SHIELD_VIDEOS: "shieldVideos", // 屏蔽参会人视频
  OPEN_VIDEOS: 'openVideos', // 请求参会人开启视频
  INVITATION_UPLOADLOG: 'invitationUploadLog', // 请求参会人上传日志


  START_RECORD: "startRecord", // 参会人开始录制
  STOP_RECORD: "stopRecord", // 停止参会人录制

  PAUSE_RECORD: "pauseRecording", // 暂停录制
  RESUME_RECORD: "resumeRecording", // 恢复录制

  REMOVE_SELF: "removeSelf", // 踢出自己的其他设备
  REMOVE_USER: "removeUser", // 踢出其他用户

  RAISE_HAND: "raiseHand", // 举手
  SINGLE_HAND_DOWN: "singleHandsDown", // 单个成员手放下
  ALL_HAND_DOWN: "allHandsDown", // 多个成员手放下

  // 全局会议通知
  END_MEET: "endConference", // 结束会议
  FORCE_END_MEET: "forceEndConference", // 强制结束会议

  LOCK_CONFERENCE: "lockConference", // 锁定会议
  UNLOCK_CONFERENCE: "unLockConference", // 解锁会议
  MATURITY_NOTICE: "maturityNotice", // 免费会议结束前通知

  ALLOW_SELF_UNMUTE: "allowSelfUnmute", // 允许成员自我解除静音
  FORBID_SELF_UNMUTE: "forbidSelfUnmute", // 禁止成员自我解除静音

  OPEN_PLAY_TIPS: "openPlayTips", // 成员加入会议时开启播放提示音
  CLOSE_PLAY_TIPS: "closePlayTips", // 成员加入会议时关闭播放提示音

  OPEN_MUTE_JOIN_MEETING: "openMuteJoinMeeting", // 成员加入会议开启静音
  CLOSE_MUTE_JOIN_MEETING: "closeMuteJoinMeeting", // 成员加入会议关闭静音

  ALL_FORCE_MUTE: "allForceMute", // 全体强制静音
  ALL_UNFORCE_MUTE: "allUnForceMute", // 全体非强制静音
  ALL_UNMUTE: "allUnMute", // 全体解除静音

  SHARE_PERMISSIONS_ALL: "sharePermissionsForAll", // 所有人可以共享
  SHARE_PERMISSIONS_HOST: "sharePermissionsForHost", // 仅主持人可共享

  RECORD_PERMISSIONS_ALL: "recordPermissionForAll", // 所有人都可以录制
  RECORD_PERMISSIONS_HOST: "recordPermissionForHost", // 仅主持人录制

  SCHEDULE_PERMISSIONS_ALL: "settingAgendaPermissionForAll", // 会议议程所有人可见
  SCHEDULE_PERMISSIONS_HOST: "settingAgendaPermissionForHost", // 会议议程仅主持人可见

  // 云录制信令通知
  START_CLOUDRECORD : "startCloudRecord", //开始云录制
  START_BACK_CLOUDRECORD : "startBackCloudRecord",//开始云录准备好了 
  PAUSE_CLOUDRECORD : "pauseCloudRecord", //暂停云录制
  RESUME_CLOUDRECORD : "resumeCloudRecord", //恢复云录制
  RESUME_BACK_CLOUDRECORD : "resumeBackCloudRecord", //恢复云录制准备好了
  STOP_CLOUDRECORD : "stopCloudRecord", //结束云录制
  INITIATE_QUESTIONNAIRE : "initiateQuestionnaire", //收到问卷消息
};

//测试网络 图片url常量 TODO:
export const NETWORK_TEST_URL =
  "https://www.100doc.com.cn/themes/simpleboot3/public/assets/images/top_logo.png";

// 最大分享帧率
export const MAX_SHARE_VIDEO_FPS = 10;

//节流时间
export const THROTLE_TIME = 2000;
export const MEDIA_THROTLE_TIME = 500;

//音频设备默认音量值
export const DEFAULT_VOLUME = 100;

export const MAX_AUDIO_TRANSPORT = 17;

//chrome最低版本限制
export const CHROME_VERSON_MIN = 75;

//edge最低版本限制
export const EDGE_VERSON_MIN = 92;

//safari最低版本限制
export const SAFARI_VERSON_MIN = 14;

//SDK通知需要toast
export const TOAST_ERR_CODE = [80000306];

//SDK通知需要弹窗错误码 todo 80000332合上笔记本再打开会通知需要解决
export const DIALOG_ERR_CODE = [
  80000301, 80000302, 80000303, 80000304, 80000305, 80000333, 80000334,
];

//SDK通知需要弹窗并返回的错误码
export const DIALOG_BACK_ERR_CODE = [
  80000101, 80000102, 80000103, 80000104, 80000105, 80000106, 80000107,
  80000206,
];

//SDK通知需要loading的错误码
export const NEED_LOADING_CODE = [
  80000502, 80000503, 80000504, 80000505, 80000508, 80000202, 80000203,
  80000204, 80000205,
];

//SDK错误提示映射
export const ERR_DATE_MAP = {
  80000101: {
    title: "加入会议失败",
    describe: "",
  },
  80000102: {
    title: "加入会议失败",
    describe: "",
  },
  80000103: {
    title: "加入会议失败",
    describe: "",
  },
  80000104: {
    title: "加入会议失败",
    describe: "",
  },
  80000105: {
    title: "加入会议失败",
    describe: "",
  },
  80000106: {
    title: "加入会议失败",
    describe: "",
  },
  80000206: {
    title: "加入会议失败",
    describe: "网络连接异常，请检查后重试",
  },
  80000207: {
    title: "离开会议失败",
    describe: "",
  },
  80000301: {
    title: "打开摄像头失败",
    describe: "",
  },
  80000302: {
    title: "打开麦克风失败",
    describe: "",
  },
  80000303: {
    code: 80000303,
    title: "打开摄像头失败，无法获取摄像头权限",
    describe:
      "请检查是否给医百会议开启了摄像头权限，或在给予权限后请尝试重启医百会议",
  },
  80000304: {
    code: 80000304,
    title: "打开麦克风失败，无法获取麦克风权限",
    describe:
      "请检查是否给医百会议开启了麦克风权限，或在给予权限后请尝试重启医百会议",
  },
  80000332: {
    title: "无摄像头设备",
    code: 80000332,
    describe: "未检测到您摄像头设备，请插入摄像头设备以供使用",
  },
  80000333: {
    title: "无音频（麦克风）设备",
    describe: "未检测到您麦克风设备，请插入麦克风设备以供使用",
  },
  80000334: {
    code: 80000334,
    title: "无音频（扬声器）设备",
    describe: "未检测到您的扬声器设备，请插入扬声器设备以供使用",
  },
 
};
export const SDK_ERROR =
{
    // 成功
    SUCCESS : 0,
    INIT_FAILED : 1,
    // 参数错误
    PARAMETERS : 2,
    // 超出范围错误
    OUT_OF_RANGE : 10000,
    // 不存在错误
    NO_EXIST : 20000,
    // 手机号码格式错误
    PHONE_FORMAT : 3,
    // 邮箱格式错误
    EMAIL_FORMAT : 4,
    // 手机未注册
    PHONE_NOT_REGISTER : 5,
    // 已发送手机验证码
    VERIFYCODE_SENT : 6,
    // 邮箱未注册
    EMAIL_NOT_REGISTER : 7,
    // 服务器返回的一个错误
    CODE_FROM_SERVER : 8,
    // 帐号被停用
    ACCOUNT_SUSPENDED : 9,
    // 用户名或密码错误，请检查后重试
    ACCOUNT_PASSWORDERROR : 10,
    // 错误的验证码
    WRONG_VERIFYCODE : 11,
    // 登录失败
    LOGIN_FAILED : 12,
    // 登录成功
    LOGIN_SUCCESS : 13,
    // 还未登录
    NOT_LOGIN : 14,
    // 获取用户信息失败，请检查后重试
    GET_USERINFO : 16,
    // 修改用户名失败
    CHANGE_USERNAME : 17,
    // 原密码和新密码一致
    OLDPASSWORD_EQUAL_NEWPASSWORD : 18,
    // 原密码或新密码为空
    OLDPASSWORD_OR_NEWPASSWORD_EMAPTY : 19,
    // 改密码失败
    CHANGE_PASSWORD : 20,
    // 修改个人会议号信息失败
    CHANGE_PERSONAL_MEETINGINFO : 21,
    // 密码格式错误
    PASSWORD_FORMAT : 22,
    // 创建会议失败
    SDKERR_CREATE_MEETING : 23,
    // 当前有正在召开的会议
    SDKERR_MEETING_NOT_END : 24,
    // 修改会议失败
    SDKERR_MODIFY_MEETING : 25,
    // 获取会议信息失败
    SDKERR_GET_MEETINGINFO : 26,
    // 删除会议失败
    SDKERR_DELETE_MEETING : 27,
    // 删除历史会议失败
    SDKERR_DELETE_HISTORY_MEETING : 28,
    // 删除会议时，会议正在进行中
    SDKERR_DELETE_OPENNING_MEETING : 29,
    // 获取预约会议列表错误
    SDKERR_GET_SCHDULE_MEETING_LIST : 30,
    // 获取历史会议列表错误
    SDKERR_GET_HISTORY_MEETING_LIST  : 31,
    // 获取今日会议列表错误
    SDKERR_GET_TODAY_MEETING_LIST : 32,
    // 新增议程错误
    SDKERR_CREATE_MEETING_AGENDA : 33,
    // 获取议程地址错误
    SDKERR_GET_AGENDA_URL : 34,

    // // 开始会议失败
    // SDKERR_START_MEETING,
    // // 加入会议失败
    // SDKERR_JOIN_MEETING,
    // // 会议号不存在
    // SDKERR_MEETINGNO_NO_EXIST,
    // // 会议已结束
    // SDKERR_MEETING_ENDED,
    // // 会议已锁定
    // SDKERR_MEETING_LOCKED,
    // // 会议人数已满
    // SDKERR_MEETING_OVERFLOW,
    // // 会议密码错误
    // SDKERR_INVALID_MEETING_PASSWORD,
    // // 请输入会议密码
    // SDKERR_INPUT_MEETING_PASSWORD,
    // // 等待主持人入会
    // SDKERR_WAIT_HOST_ENTER,
    // // 进入房间失败
    // SDKERR_ENTER_ROOM,
    // // 打开本地麦克风失败
    // SDKERR_OPEN_MIC,
    // // 关闭本地麦克风失败
    // SDKERR_CLOSE_MIC,
    // // 打开摄像头失败
    // SDKERR_OPEN_CAMERA,
    // // 没有权限
    // SDKERR_INVALID_AUTHORITY,
}
