import Vue from "vue";
import Router from "vue-router";

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const routerReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch((error) => error);
};

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index/index.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    component: () => import("@/views/guide/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: '/appointment/:meetingId?',
    name: 'appointment',
    component: () => import('@/views/appointment/index.vue')
  },
  {
    path: '/fast',
    name: 'fast',
    component: () => import('@/views/fast/index.vue')
  },
  {
    path: "/meeting/:userID",
    name: "meeting",
    component: () => import("@/views/meeting/Meeting.vue"),
  },
  {
    path: '/meetSetting',
    name: 'meetSetting',
    component: () => import('@/views/meetSetting/index.vue')
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/views/meetSetting/currency/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/meetSetting/about/index.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('@/views/meetSetting/userInfo/index.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/meetSetting/user/index.vue')
  },
  {
    path: '/meetSetInfo',
    name: 'meetSetInfo',
    component: () => import('@/views/meetSetting/meetSetInfo/index.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/meetSetting/changePassword/index.vue')
  },
  
  {
    path: '/changeUserName',
    name: 'changeUserName',
    component: () => import('@/views/meetSetting/changeUserName/index.vue')
  },
  {
    path: '/networkTest',
    name: 'networkTest',
    component: () => import('@/views/meetSetting/networkTest/index.vue')
  },
  {
    path: '/showMeetTime',
    name: 'showMeetTime',
    component: () => import('@/views/meetSetting/showMeetTime/index.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/meetSetting/agreement/index.vue')
  },
  
  
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/login/register.vue"),
  },
  {
    path: "/forgetpassword",
    name: "forgetpassword",
    component: () => import("@/views/login/forgetpw.vue"),
  },
  {
    path: "/finish",
    name: "finish",
    component: () => import("@/views/login/Finish.vue"),
  },
  {
    path: "/errorRegister",
    name: "errorRegister",
    component: () => import("@/views/login/errorRegister.vue"),
  },
  {
    path: "/finishRegister",
    name: "finishRegister",
    component: () => import("@/views/login/finishRegister.vue"),
  },
  {
    path: "/setNewPwd",
    name: "setNewPwd",
    component: () => import("@/views/login/setNewPwd.vue"),
  },
  // {
  //   path: '/land',
  //   name: 'land',
  //   component: () => import('@/views/land/index.vue')
  // },
  {
    path: "/join",
    name: "join",
    component: () => import("@/views/join/index.vue"),
  },
  // {
  //   path: '*', // 页面不存在的情况下会跳到登录页
  //   redirect: '/login'
  // }
];

const router = new Router({
  mode: "history", // hash
  routes,
});

export default router;
