
/**
 * 项目封装的 storage 类
 * @param {*} getItem 获取 web 存储值，如果获取 appHide -- 需要判断 过期时间
 */
export default class MeetingStorage {
  getItem(key) {
    if(key != 'appHide') {
      return localStorage.getItem(key)
    }

    // 劫持 appHide
    const exceptionExitExpires = localStorage.getItem('exceptionExitExpires');

    // 没有异常退出时间
    if(!exceptionExitExpires) return null;

    // 过期了
    const currentTime = new Date().getTime();
    if(currentTime > parseInt(exceptionExitExpires)) return null;

    // 没有超期，返回正常值
    return localStorage.getItem(key);

  }
}



