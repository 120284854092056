var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
/**
 * 医百 meet SDK
 */
import YB_RTC from "@ybmeet/yb_rtc_sdk";
import Logan from "logan-web";
import Logger from './utils/logger';
import IM_EVENT from "./plugins/IM/lib/api/im-events";
import configs from './plugins/config';
import { EventEmitter } from 'events';
import { SDK_TAG_NAME } from './constant';
var logger = new Logger(SDK_TAG_NAME + " EnhancedEventEmitter");
var EnhancedEventEmitter = /** @class */ (function (_super) {
    __extends(EnhancedEventEmitter, _super);
    function EnhancedEventEmitter() {
        var _this = _super.call(this) || this;
        _this.setMaxListeners(Infinity);
        return _this;
    }
    EnhancedEventEmitter.prototype.safeEmit = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var numListeners = this.listenerCount(event);
        try {
            return this.emit.apply(this, __spread([event], args));
        }
        catch (error) {
            logger.error('safeEmit() | event listener threw an error [event:%s]:%o', event, error);
            return Boolean(numListeners);
        }
    };
    EnhancedEventEmitter.prototype.safeEmitAsPromise = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        try {
                            _this.emit.apply(_this, __spread([event], args, [resolve, reject]));
                        }
                        catch (error) {
                            logger.error('safeEmitAsPromise() | event listener threw an error [event:%s]:%o', event, error);
                            reject(error);
                        }
                    })];
            });
        });
    };
    return EnhancedEventEmitter;
}(EventEmitter));
var YBMeetSDK = /** @class */ (function (_super) {
    __extends(YBMeetSDK, _super);
    function YBMeetSDK(options) {
        var _this = _super.call(this) || this;
        _this.plugins = {};
        // initLocalDeviceInfo()
        sessionStorage.setItem('YB_MEET_SDK_ENV', options.mode);
        _this.init(options);
        return _this;
    }
    YBMeetSDK.prototype.init = function (options) {
        this.roomClient = new YB_RTC();
        this.installPlugins(options);
    };
    /**
    * 写入日志
    */
    YBMeetSDK.prototype.writeLog = function (msg, type) {
        Logan.log(msg, type);
    };
    /**
     * 上传日志
     * @returns {Promise}
     */
    YBMeetSDK.prototype.uploadLogfile = function (options) {
        if (options === void 0) { options = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var data, error_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, Logan.report(options)];
                                case 1:
                                    data = _a.sent();
                                    resolve(data);
                                    return [3 /*break*/, 3];
                                case 2:
                                    error_1 = _a.sent();
                                    reject(error_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    YBMeetSDK.prototype.installPlugins = function (options) {
        var _this = this;
        console.log(configs);
        Object.values(configs)
            .forEach(function (item) {
            // eslint-ignore
            _this[item.key] = new item.ref(_this, __assign({}, options));
        });
    };
    YBMeetSDK.IM_EVENT = IM_EVENT;
    return YBMeetSDK;
}(EnhancedEventEmitter));
export default YBMeetSDK;
