/*********
 * 美颜
 * ********** */

import Vue from 'vue'

import { FACE_CACHE_KEY } from "@/constant/index"
import { loadLocal, saveLocal } from '@/utils/tool'
import { getLocalUserInfo } from "@/utils/auth"
import { loganLog } from "@/utils/log"

import { beautyAppType } from '@/config/index'

const state = {
    support: false, //是否开放 
    number: 0, //美颜数值
    level: 0, //美颜等级 -1 关闭 0 轻度 1 中度 2 重度 3 自定义
    type: 0, //美颜类型 -1 恢复 0 美白 1 磨皮 2 瘦脸 3 削脸 4 大眼 5 下巴
    typeNumbers: [0, 0, 0, 0, 0, 0] //各个美颜类型对应的选中值 存储
}

const mutations = {
    updateGlobalFaceState(state, data) {
        for (const key in data) {
            state[key] = data[key]
        }
    },

    setOneTypeNumber(state, { type, number }) {
      return new Promise(async (resolve, reject) => {
         try {
            state.typeNumbers.splice(type, 1, number)
            this.commit("face/setFaceCacheInfo") //更新存储

            await this.commit("face/setSingleBeautyCustom", {
              type,
              number
            }) //应用SDK
            resolve()
         } catch (error) {
           reject(error)
         }
      })
    },
    
    // 设置缓存数据 缓存格式 "LL|XX,XX,XX" LL:美颜级别 XX:美颜类型值
    setFaceCacheInfo(state) {
      let cacheFullKey = FACE_CACHE_KEY //用户未登录 公共存储，正常退会 需要清除

      const typeNumbersStr = state.typeNumbers
        .join(',')
 
       saveLocal(cacheFullKey, `${state.level}|${typeNumbersStr}`)
    },

    //设置美颜级别 -1 关闭
    setBeautyLevel(state, level) {
      return new Promise(async (resolve, reject) => {
        if (level > -1) {
          state.level = level
        }
        //0 关闭 1 轻度 2 中度 3 重度
        if (level < 3) {
          try {
            console.error('[setBeautyLevel]美颜sdk 调用了 传参level:', level + 1)
            const beautyManager = await Vue.prototype.sdk.rtc.getBeautyManager(beautyAppType)
            beautyManager.setBeautyLevel(level + 1)
          } catch (error) {
            console.error('设置美颜级别出错', error)
            loganLog('设置美颜级别出错', error)
            reject(error)
          }
        }
        resolve()
      })
    },

    //设置美颜自定义 某一个
    setSingleBeautyCustom(state, { type, number }) {
      return new Promise(async (resolve, reject) => {
        state.type = type
        state.number = number
        if (state.level === 3) {
          try {
            console.error('[setSingleBeautyCustom]美颜sdk 调用了')
            const beautyManager = await Vue.prototype.sdk.rtc.getBeautyManager(beautyAppType)

            let appValue = Number(parseFloat(
              number/10
            )
            .toFixed(1))

            console.error('当前美颜实际应用的值:', appValue)

            switch (type) {
                case 0: //美白
                  beautyManager.setWhitenessLevel(appValue)
                  break;

                case 1: //磨皮
                  beautyManager.setDermabrasionLevel(appValue)
                  break;

                case 2: //瘦脸
                  beautyManager.setFaceSlimLevel(appValue)
                  break;

                case 3: //削脸 
                  beautyManager.setFaceShaveLevel(appValue)
                  break;

                case 4: //大眼
                  beautyManager.setEyeScaleLevel(appValue)
                  break;

                case 5: //下巴
                  beautyManager.setChinLevel(appValue)
                  break;

                default:
                  break;
            }
            resolve()
          } catch (error) {
            console.error('设置美颜自定义出错', error)
            loganLog('设置美颜自定义出错', error)
            reject(error)
          }
        }
      })
    },

    //自定义的全部应用
    setAllBeautyCustom(state) {
      return new Promise(async (resolve, reject) => {
        if (state.level === 3 && state.typeNumbers.length === 6) {
          try {
            console.error('[setAllBeautyCustom]美颜sdk 调用了')
            const beautyManager = await Vue.prototype.sdk.rtc.getBeautyManager(beautyAppType)

            const appNumbers = state.typeNumbers
            .map(
              number => 
                Number(parseFloat(
                  number/10
                )
                .toFixed(1))
            ) 
           

            console.error('当前自定义美颜实际应用的值:', appNumbers)

            //美白
            beautyManager.setWhitenessLevel(appNumbers[0])  
    
            //磨皮
            beautyManager.setDermabrasionLevel(appNumbers[1])
              
            //瘦脸
            beautyManager.setFaceSlimLevel(appNumbers[2])  
    
            //削脸 
            beautyManager.setFaceShaveLevel(appNumbers[3])  
    
            //大眼
            beautyManager.setEyeScaleLevel(appNumbers[4])
    
            //下巴
            beautyManager.setChinLevel(appNumbers[5])

            resolve()
          } catch (error) {
            console.error('自定义的全部应用出错', error)
            loganLog('自定义的全部应用出错', error)
            reject(error)
          }
          
        }
      })
    },

    //初始化 默认轻度 优先缓存
    //解析缓存数据 缓存格式 "LL|XX,XX,XX" LL:美颜级别 XX:美颜类型值
    applyFaceStateFromCache(state, isInitLoad) {
      return new Promise(async (resolve, reject) => {
        const userInfo =  getLocalUserInfo()
        console.error(userInfo.userId)

        let cacheFullKey = FACE_CACHE_KEY //用户未登录 公共存储，正常退会 需要清除

        const faceCacheStr = loadLocal(cacheFullKey)
        
        
        if (!faceCacheStr  || !faceCacheStr.includes('|')) {
          state.level = isInitLoad ? 0 : -1 //首次加载时，默认应用轻度 否则关闭
          console.log(`缓存空 默认${isInitLoad ? '开启轻度' : '关闭'}美颜 faceLevel ${state.level}`)
      
          await this.commit("face/setBeautyLevel", state.level)
          resolve()
          return
        }

        const level = faceCacheStr.split('|')[0]
        state.level = Number(level)

        if (state.level === 3) {
          const types = faceCacheStr.split('|')[1].split(',')

          state.typeNumbers = types.map(n => Number(n))

          state.number = state.typeNumbers[state.type] || 0

          //批量应用
          console.log('缓存自定义 批量应用缓存中..')
          await this.commit("face/setAllBeautyCustom")
        } else {
          console.log(`缓存非自定义 faceLevel ${state.level}`)
          await this.commit("face/setBeautyLevel", state.level)
        }
        resolve()
      })
    },


    // reset 默认
    reset(state) {
        state.number = 0
        state.level = 0
        state.support = false

        state.type = 0
        state.typeNumbers = [0, 0, 0, 0, 0, 0] 
    },

}

// 计算属性
const getters = {

    // 获取指定id的用户信息
    // getUser: (state) => (userId) => {
    // },

    // 大图显示逻辑
    // userMain: (state,getters) => {
    //   //Vue.prototype.$configs.
    //   //getters.getSpeakUser
    // },
}

const actions = {  
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
