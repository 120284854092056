<template>
  <div id="app">
    <error-handler></error-handler>
    <network-error></network-error>
    <router-view ref="router-view" v-if="isRouterAlive"/>
  </div>
</template>
<script>
import cryptoRandomString from 'crypto-random-string'
import { setUuid } from "@/utils/auth"
import ErrorHandler from './components/ErrorHandler.vue';
import NetworkError from './components/notify/NetworkError.vue';

import { beautyAppType } from '@/config/index'
import { preloadMutImage } from "@/utils/image"

import { isSupportFace } from "@/utils/mobile"


export default {
  components: { ErrorHandler, NetworkError },

  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  },

  async created() {
    setUuid(cryptoRandomString({length: 10}))

    //初始化美颜sdk 需要在RoomClent 实例化之后一起执行
    //如果支持美颜
    if (isSupportFace()) {
      try {
        await this.sdk.rtc.getBeautyManager(beautyAppType)
        console.error('初始化美颜getBeautyManager了')

        this.$store.commit('face/updateGlobalFaceState', {
          support: true
        })
      } catch (error) {
        console.error('初始化美颜getBeautyManager失败',error)
      }
      
      //提前预加载自定义中美颜一些图标文件 动态图标无法缓存（断网使用）
      preloadMutImage()
    }
  },

  mounted() {
  }
};
</script>

<style lang="less">
html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app{
  margin: 0 auto;
  width: 750px;
  height: 100%;
}

div {
  box-sizing: border-box;
}

//全局 js调用弹窗的默认样式调整
.default-dialog .van-dialog__header{
  padding-left: 50px;
  padding-right: 50px;
}
.van-overlay {
   overflow: hidden !important;
 }
 .toastBottom{
  top:80% !important;
}
</style>